import Head from "next/head";
import Script from "next/script";
import { useEffect, useState } from "react";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
// import SqaureIconButton from "../components/SqaureIconButton";
import FacebookMesenger from "../components/FacebookMesenger";

import AdControllerContext from "../contexts/AdControllerContext";

import { analytics, logAdblockDetected } from "../lib/firebaseAnalytic";
import { gtagGoogleAdsInit } from "../lib/gtag";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../styles/globals.css";

function MyApp({ Component, pageProps }) {
  const [showToTopButton, setShowToTopButton] = useState(false);
  const [adblockDetected, setAdblockDetected] = useState(false);

  async function loadBootstrap() {
    const bootstrap = await import("bootstrap/dist/js/bootstrap");
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
  }

  useEffect(() => {
    loadBootstrap();

    analytics();

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowToTopButton(true);
      } else {
        setShowToTopButton(false);
      }
    });
  }, []);

  const loadAdsense = () => {
    var ads = document.getElementsByClassName("adsbygoogle").length;
    for (var i = 0; i < ads; i++) {
      try {
        (adsbygoogle = window.adsbygoogle || []).push({});
      } catch (e) {
        console.log("adsbygoogle", e);
      }
    }
  };

  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // };

  return (
    <>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/th-text-tools-prod-public/cover/og-image.png"
        />
        <meta
          property="og:image:url"
          content="https://storage.googleapis.com/th-text-tools-prod-public/cover/og-image.png"
        />
        <meta
          property="twitter:image"
          content="https://storage.googleapis.com/th-text-tools-prod-public/cover/og-image.png"
        />
        <script
          id="cookie-wow-script"
          strategy="beforeInteractive"
          src="https://cookiecdn.com/cwc.js"
        />
        {process.env.cwcID != "" && (
          <script
            id="cookie-wow"
            strategy="beforeInteractive"
            src={`https://cookiecdn.com/configs/${process.env.cwcID}`}
            data-cwcid={process.env.cwcID}
          />
        )}
      </Head>

      <Script
        id="gtag-ads-init"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.googleAdsGtag}`}
        strategy="afterInteractive"
        onLoad={gtagGoogleAdsInit}
      />

      <Script
        async
        src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${process.env.adsenseUnitId}`}
        crossorigin="anonymous"
        strategy="afterInteractive"
        onLoad={() => {
          console.log("Adsense loaded");
          setAdblockDetected(false);
          loadAdsense();
        }}
        onError={(e) => {
          console.error("Adsense failed to load", e);
          logAdblockDetected();
          setAdblockDetected(true);
        }}
      />

      <AdControllerContext
        adblockDetected={adblockDetected}
        affiliateDisplayConfig={null}
      >
        <NavBar />
        <Component {...pageProps} />
        <Footer />
      </AdControllerContext>

      <FacebookMesenger />

      {/* {showToTopButton && (
        <SqaureIconButton onClick={scrollToTop}>
          <i className="bi bi-caret-up-fill" />
        </SqaureIconButton>
      )} */}
    </>
  );
}

export default MyApp;
