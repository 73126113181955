import { createContext, useContext } from "react";

const AdControllerContext = createContext(false);
const rand = Math.random();

export function shouldDisplayAffiliate(affiliateDisplayConfig) {
  if (affiliateDisplayConfig === undefined || affiliateDisplayConfig === null) {
    console.log("Null affiliate display config");
    return false;
  } else {
    return true;
  }
}

export function useAdControllerContext() {
  return useContext(AdControllerContext);
}

export const AdControllerProvider = ({
  adblockDetected,
  affiliateDisplayConfig,
  children,
}) => {
  return (
    <AdControllerContext.Provider value={[adblockDetected]}>
      {children}
    </AdControllerContext.Provider>
  );
};

export default AdControllerProvider;
