export function gtagGoogleAdsInit() {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  const gtagOption = {
    anonymize_ip: true,
  };
  if (window.cwcIsUserAccept && window.cwcIsUserAccept("analytics")) {
    console.log("Init gtag with anonymize ip");
    gtagOption.anonymize_ip = false;
  } else {
    console.log("Init gtag without anonymize ip");
  }
  gtag("js", new Date());
  gtag("config", process.env.googleAdsGtag, gtagOption);
}
