import { getApps, initializeApp } from "firebase/app";

let firebase;
if (getApps().length < 1) {
  console.log("Init new firebase");
  firebase = initializeApp(process.env.firebaseConfig);
} else {
  console.log("Get existing firebase");
  firebase = getApps()[0];
}

export { firebase };
