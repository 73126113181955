import Link from "next/link";
import Image from "next/image";

import icon from "../images/logo.png";

import { LocalImageLoader } from "../lib/imageLoader";

const Footer = () => (
  <div className="border-top bg-white">
    <footer className="container d-flex flex-wrap justify-content-center align-items-center py-2">
      <div className="d-flex flex-column align-items-center">
        <Link href="/" passHref legacyBehavior>
          <a className="text-muted text-decoration-none lh-1">
            <Image
              src={icon}
              loader={LocalImageLoader}
              alt="cancount-logo"
              width={24}
              height={24}
              className="d-inline-block"
            />
          </a>
        </Link>
        <p className="m-0 text-center text-muted">© 2022 cancount.app</p>
        <p>
          <Link href="/privacy-policy" passHref legacyBehavior>
            <a>นโยบายความเป็นส่วนตัว</a>
          </Link>{" "}
          |{" "}
          <Link href="/license" passHref legacyBehavior>
            <a>ใบอนุญาตสำหรับเว็บไซต์</a>
          </Link>{" "}
        </p>
      </div>
    </footer>
  </div>
);

export default Footer;
