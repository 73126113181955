import {
  initializeAnalytics,
  logEvent as firebaseLogEvent,
} from "firebase/analytics";

import { firebase as firebaseApp } from "./firebase";

let firebaseAnalytics;

function analytics() {
  const gtagOption = {
    ad_storage: "denied",
    analytics_storage: "denied",
    allow_google_signals: false,
    allow_ad_personalization_signals: false,
    anonymize_ip: true,
    restricted_data_processing: false,
    ads_data_redaction: true,
  };
  if (window !== undefined && window !== null) {
    console.log("window.cwcIsUserAccept", window.cwcIsUserAccept);
    console.log(
      "cwcIsUserAccept:bool",
      window.cwcIsUserAccept !== undefined &&
        (window.cwcIsUserAccept("analytics") ||
          window.cwcIsUserAccept("marketing"))
    );
    if (
      window.cwcIsUserAccept &&
      (window.cwcIsUserAccept("analytics") ||
        window.cwcIsUserAccept("marketing"))
    ) {
      console.log(
        "window.cwcIsUserAccept(analytics)",
        window.cwcIsUserAccept("analytics")
      );
      console.log(
        "window.cwcIsUserAccept(marketing)",
        window.cwcIsUserAccept("marketing")
      );

      console.log("Init firebase analytic without anonymize ip");
      gtagOption.anonymize_ip = false;
      if (window.cwcIsUserAccept("analytics")) {
        gtagOption.analytics_storage = "granted";
      }
      if (window.cwcIsUserAccept("marketing")) {
        gtagOption.ad_storage = "granted";
        gtagOption.allow_google_signals = true;
        gtagOption.allow_ad_personalization_signals = false;
      }
    } else {
      console.log("Init firebase analytic with anonymize ip");
    }
  } else {
    console.log("Init firebase analytic with anonymize ip");
  }
  console.log("GtagOption", gtagOption);
  firebaseAnalytics = initializeAnalytics(firebaseApp, gtagOption);
}

function logAffiliateClickEvent(e) {
  console.log("logAffiliateClickEvent", e.target.getAttribute("data-key"));
  firebaseLogEvent(firebaseAnalytics, "affiliate_click", {
    id: e.target.getAttribute("data-key"),
  });
}

function logAppUsage(e, action = "app_usage") {
  let logVal = {
    id: e.target.getAttribute("id"),
  };
  if (e.target.type == "checkbox") {
    logVal.checked = e.target.checked;
  } else if (e.target.type == "file") {
    logVal.accept = e.target.accept;
  }
  firebaseLogEvent(firebaseAnalytics, action, logVal);
  console.log("logAppUsage", action, logVal);
}

function logFilSelectorUsage(type) {
  firebaseLogEvent(firebaseAnalytics, "app_usage", {
    id: type,
  });
  console.log("logFilSelectorUsage", type);
}

function logAdblockDetected() {
  firebaseLogEvent(firebaseAnalytics, "adblock_detected");
  console.log("logAdblockDetected");
}

export {
  analytics,
  logAffiliateClickEvent,
  logAppUsage,
  logAdblockDetected,
  logFilSelectorUsage,
};
