import Link from "next/link";
import Image from "next/image";

import icon from "../images/logo.png";

import { LocalImageLoader } from "../lib/imageLoader";

const NavBar = () => (
  <nav className="shadow-sm navbar navbar-expand-lg navbar-light bg-white">
    <div className="container">
      <Link href="/" passHref legacyBehavior>
        <a className="m-0 h4 navbar-brand d-flex align-items-center text-muted">
          <Image
            src={icon}
            loader={LocalImageLoader}
            alt="cancount-logo"
            width={24}
            height={24}
            className="d-inline-block align-text-top"
          />
          <span>&nbsp;</span>
          <span>CanCount</span>
        </a>
      </Link>

      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbar-menu"
        aria-controls="navbar-menu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <ul
        id="navbar-menu"
        className="m-0 collapse navbar-collapse justify-content-end"
      >
        <li className="nav-item">
          <Link href="/" passHref legacyBehavior>
            <a className="nav-link px-2 text-muted">หน้าหลัก</a>
          </Link>
        </li>
        <li className="nav-item">
          <Link href="/contents" passHref legacyBehavior>
            <a className="nav-link px-2 text-muted">คลังความรู้</a>
          </Link>
        </li>
        <li className="nav-item">
          <Link href="/faq" passHref legacyBehavior>
            <a className="nav-link px-2 text-muted">คำถามที่พบบ่อย</a>
          </Link>
        </li>
      </ul>
    </div>
  </nav>
);

export default NavBar;
